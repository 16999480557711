<template>
  <div class="empty">
    <img src="~assets/images/icons/empty.svg"
         alt="" />
    <span>当前没有{{ info }}信息</span>
  </div>
</template>

<script>
export default {
  name: "Empty",
  props: {
    info: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.empty {
  width: 100%;
  min-height: 100%;
  flex: 1;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  pointer-events: none;

  &.small {
    min-height: unset;

    img {
      width: 48px;
      height: 48px;
      margin-bottom: 10px;
    }

    span {
      font-size: 12px;
      font-weight: normal;
    }
  }

  img {
    width: 128px;
    height: 128px;
    opacity: 0.4;
    margin-bottom: 20px;
  }

  span {
    width: 100%;
    text-align: center;
    line-height: 1;
    font-size: 20px;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.4);
  }
}
</style>