<template>
  <vls-preview
        v-if="previewTemp"
        :comps="screenData"
        :mode="1"
        :prjModelList="screenObj.prjModelList.value">
      </vls-preview>
  <!-- <vls-overview :comps="screenObj.screen.value.page[screenObj.selectPage.value.id].components"
      :isEdit="false"
      :isShare="isShare"
      :backgroundComp="screenObj.backgroundComp.value" 
      :isCover="screenObj.screen.value.type"
      :screenWidth="screenObj.screen.value.screenWidth"
      v-if="previewTemp"
      :screenHeight="screenObj.screen.value.screenHeight"/> -->
  <div class="left" id="layout-btn-group">
  </div>
  <window :title="authCode.title"
          :shadow="true"
          class="dark"
          @windowHide="shareHide()"
          windowId="version-share"
          v-show="authCode.isShow">
    <template #body>
      <div class="line pd-20">
        <div class="insert-h">
          <div class="attr">授权码</div>
          <div class="val">
            <el-input v-model="authCode.code"
                      placeholder="请输入授权码" />
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="line pd-20 align-right">
        <el-button size="small"
                    @click="shareHide()">取消</el-button>
        <el-button size="small"
                    type="primary"
                    @click="saveShareCode()">确定</el-button>
      </div>
    </template>
  </window>
</template>

<script>
import _ from 'underscore';
import Window from 'components/common/forms/Window';
import { ref, onMounted, reactive, getCurrentInstance, provide  } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { init, adaptInitComp, getScreenData } from "components/common/hook/preview";
import { useMutataions } from "components/util/mapMutations";

export default {
  name: 'ScreenPreview',
  components: {
    Window
  },
  props: {
    prjId: {
      type: String,
      default: ''
    },
    obj: {
      type: Object,
      default: null
    },
    isShare: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, {}) {
    const $route = useRoute();
    const store = useStore();
    let id = $route.query.id, code= $route.query.code, verId = $route.query.verId, shareId = $route.query.shareId;
    let screenData = ref({});
    let screenObj = {
      screen: ref({
        screenWidth: 1900,
        screenHeight: 1300,
        page: { "-1": { components: [] } }
      }),
      pageInfos: ref([]),
      selectPage: ref({ id: "-1" }),
      copyComList: [],
      pageConfigList: ref([]),
      prjModelList: ref([]),
      linePosObj: ref({}),
      linesPos: ref([]),
      operationObj: [], //用于撤销、恢复
      operationIndex: -1,
      backgroundComp: ref(null)
    }, //存储大屏数据变量
      otherObj = {
        project: ref({}),
        dbList: ref([]),
        tabs: ref([
          {
            id: 0,
            name: "基础"
          },
          {
            id: 1,
            name: "数据"
          },
          {
            id: 2,
            name: "事件"
          },
          {
            id: 3,
            name: "参数"
          }
        ]),
        dialogImageUrl: ref(""),
        version: reactive({
          isShow: false,
          isEdit: false,
          title: "",
          info: {
            name: "",
            description: "",
            fileIds: [],
            no: ""
          }
        }),
        snapshot: reactive({
          isShow: false,
          isEdit: false,
          title: "",
          info: {
            name: "",
            description: "",
            fileIds: [],
            no: ""
          }
        }),
        authCode: reactive({
          title: "",
          isShow: false,
          info: {
            expireType: "day14",
            value: 14,
            code: "",
            cloneCode: ""
          },
          share: {
            url: ""
          }
        }),
        files: [],
        backList: "",
        comList: ""
      }, //其他数据
      flagObj = {
        loading: ref(true),
        isPreview: ref(false),
        ctrlKey: ref(false),
        isEdit: ref(false),
        dialogVisible: ref(false),
        dynamicEdit: ref(false),
        repeatCompEdit: ref(false),
        isScreenShot: ref(false),
        isListenerActive: ref(false)
      }, //状态变量
      refObj = {
        scene: ref(null),
        layers: ref(null),
        stage: ref(null),
        config: ref(null),
        oview: ref(null),
        oviewRe: ref(null),
        historyComps: {},
        compInfoList: ref([]),
        selectGroup: ref([]),
        currentNode: ref(""),
        board: ref(null),
        dynamicComp: ref({}),
        parentParams: ref({}),
        layoutWidths: {},
        repeatWidths: {},
        sceneHistoryComp: {},
        pageParams: ref({}),
        currentPage: ref([]),
        recordDynamic: ref({}),
        jumpParams: {},
        recordRows: {}
      }, //代理对象
      staticObj = {
        unContainKeys: ref([
          "dv-active-ring-chart",
          "dv-capsule-chart",
          "dv-digital-flop",
          "dv-percent-pond",
          "dv-scroll-ranking-board",
          "dv-scroll-board",
          "dv-water-level-pond"
        ]),
        otherConfigKey: ref([
          "layout",
          "custom-scroll-num",
          "bim_earth"
        ]),
        mapKeys: ref([
          "choropleth",
          "static"
        ]),
        noAdvancedTypes: ref([
          "layout",
          "icon",
          "board",
          "dynamic-board",
          "custom-search1",
          "custom-layout1",
          "custom-calendar1",
          "vertical-line",
          "horizontal-line",
          "custom-scroll-num"
        ])
      }; //静态数据
    let chooseComp = ref([]),
    previewTemp = ref(false), authCode = ref({title: '授权码', isShow: false,  code: ''});

    //封装mapMutations
    const mutation = useMutataions("Preview", [
      "setUserScale",
      "setPrjModelList",
      "setProject"
    ]);

    let optConfig = ref({
      show: false,
      parameter: {
        adsorption: 2
      }
    })
    const global = store.state.Global.globalValues;
    const page = store.state.Global.pageValues;
    const $api = getCurrentInstance().appContext.config.globalProperties.$api; 
    const $bus = getCurrentInstance().appContext.config.globalProperties.$bus;
    const axios = getCurrentInstance().appContext.config.globalProperties.axios;
    const $message = getCurrentInstance().appContext.config.globalProperties
      .$message;

    const oview = ref(null);

    const getDetail = () => {
      if(props.obj) {
        setScreen(props.obj);
      }else if(id && !verId){
        $api.share.getScreenByPrjId({ prjId: id }).then(data => {
          setScreen(data);
        })
      } else if(id && verId){
        $api.share.getVerScreen({
          prjId: id,
          verId: verId
        }).then((data) => {
          setScreen(data);
        })
      } else {
        $api.share.getVerDataByShareId({
          shareId: shareId
        }).then(data => {
          setScreen(data);
        })
      }
    };

    const adjustAuthCode = () => {
      if(verId || shareId) {
        let args = {
          codeType: 1,
          key: verId,
          id: shareId,
          code: code
        }
        $api.share.adjustExpire(args).then(data => {
          if(data) {
            getDetail();
          } else 
            authCode.value.isShow = true;
        })
      }else {
        getDetail();
      }
    };

    const setScreen = (data) => {
      otherObj.project.value.id = data.prjId;
      $api.sp.getScreenByPrjId({ prjId: data.prjId }).then(data => {
        init({
          screenObj: screenObj,
          otherObj: otherObj,
          flagObj: flagObj,
          refObj: refObj,
          staticObj: staticObj,
          optConfig: optConfig,
          storeGlobal: global,
          storePage: page,
          commonAxios: axios,
          commonApi: $api,
          mutation: mutation,
          $message: $message
        });
        adaptInitComp(data, store, true, () => {
          screenData.value = data;
          previewTemp.value = true;
        });
      });
    };

    const shareHide  = () => {
      authCode.value.isShow = false;
      // emit('previewHide');
    };

    const saveShareCode = () => {
      let args = {
          codeType: 1,
          key: verId,
          id: shareId,
          code: authCode.value.code
        }
      $api.share.adjustExpire(args).then(data => {
        if(data) {
          getDetail();
        }
        
        authCode.value.isShow = false;
      })
    };

    const setScreenData = () => {
      let obj = getScreenData();
      screenObj = obj.screenObj;
      otherObj = obj.otherObj;
      flagObj = obj.flagObj;
      refObj = obj.refObj;
      staticObj = obj.staticObj;
    };

    provide ('setScreenData', setScreenData);
    
    onMounted(() => {
      adjustAuthCode();
    });

    return {
      oview,
      authCode,
      previewTemp,
      screenObj,
      otherObj,
      flagObj,
      refObj,
      staticObj,
      screenData,

      shareHide,
      saveShareCode,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>