const VersionNo = (rule,value) => {
    const reg = /^(V)([0-9]\d|[0-9])(.([0-9]\d|\d)){2}$/;
    return reg.test(value);
}

//校验url
const validateSqlUrl = (rule,value) => {
    const consturlregex = /^(jdbc):(mysql|sqlserver):\/\/(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5]):[\d]{1,5}(\/|;)[A-z]{1,10}/;
    return consturlregex.test(value);
}

const validateInteger = (rule, value) => {
    const reg = /^[\d]{1,5}$/;
    return reg.test(value);
}

const validatePhone = (rule, value) => {
    const reg = /(?:0|86|\+86)?1[3-9]\d{9}/;
    return reg.test(value);
}

const validateEmail = (rule, value) => {
    const reg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
    return reg.test(value);
}

//字母或数字或下划线或（非空白）字符（至少8位）
const validatePassword = (rule, value) => {
    const reg = /^\w[\S]{7,}$/i;	
    return reg.test(value);
}


export { VersionNo, validateSqlUrl, validateInteger, validatePhone, validatePassword, validateEmail }