import api from 'network/api';

const btnStatus = (spaceId, $store, callback) => {
  let memberList = $store.state.currentParam.memberListObj[spaceId];
  let admin = _.find($store.state.user.roleInfos, v => {
    return v.id == '-2' || v.id == '0' || v.id == '1';
  })
  let result = {
      addAuth: false,
      editAuth: false,
      isRoleDisabled: false,
      isExpireDisabled: false,
      delAuth: false,
      roleList: [
        {
          id: 1,
          name: '拥有者'
        }, {
          id: 2,
          name: '管理员'
        },
        {
          id: 3,
          name: '配置人员'
        }, {
          id: 4,
          name: '开发人员'
        }, {
          id: 5,
          name: '游客'
        }
      ]
  }
  if(memberList && memberList.length > 0)  {
    if(admin) {
      callback({
        addAuth: true,
        editAuth: true,
        isRoleDisabled: true,
        isExpireDisabled: true,
        delAuth: true,
        roleList: [
          {
            id: 2,
            name: '管理员'
          },
          {
            id: 3,
            name: '配置人员'
          }, {
            id: 4,
            name: '开发人员'
          }, {
            id: 5,
            name: '游客'
          }
        ]
      });
      return;
    }
    let userId = $store.state.user.userInfo.id;
    let member = memberList.filter(v => {return v.userId === userId});
    if(member && member.length > 0) {
      let date = new Date();
      let dateFormat = date.getFullYear() + '-' + ((date.getMonth() + 1) < 10 ? '0'+(date.getMonth() + 1) : (date.getMonth() + 1) ) + '-' + 
      (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
      if(Date.parse(member[0].expireDate) >= Date.parse(new Date(dateFormat))|| member[0].expireDate == null && callback) {
        result = roleAuth(member[0].roleId, result);
        callback(result);
      } 
    }else {
      callback({
        addAuth: false,
        editAuth: false,
        isRoleDisabled: true,
        isExpireDisabled: true,
        delAuth: false,
        roleList: []
      });
    }      
  }else {
    api.sp.getAllMembers({spaceId: spaceId}).then((res) => {
      memberList = res;
      if(memberList && memberList.length > 0) {
        $store.commit('setSpaceMember', {id: spaceId, list: res});
        if(admin) {
          callback({
            addAuth: true,
            editAuth: true,
            isRoleDisabled: true,
            isExpireDisabled: true,
            delAuth: true,
            roleList: [
              {
                id: 2,
                name: '管理员'
              },
              {
                id: 3,
                name: '配置人员'
              }, {
                id: 4,
                name: '开发人员'
              }, {
                id: 5,
                name: '游客'
              }
            ]
          });
          return;
        }
        let userId = $store.state.user.userInfo.id;
        let member = memberList.filter(v => {return v.userId === userId});
        if(member && member.length > 0) {
          let date = new Date();
          let dateFormat = date.getFullYear() + '-' + ((date.getMonth() + 1) < 10 ? '0'+(date.getMonth() + 1) : (date.getMonth() + 1) ) + '-' + 
          (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
          if(Date.parse(member[0].expireDate) >= Date.parse(new Date(dateFormat))|| member[0].expireDate == null && callback) {
            result = roleAuth(member[0].roleId, result);
            callback(result);
          } 
        } else {
          callback({
            addAuth: false,
            editAuth: false,
            isRoleDisabled: true,
            isExpireDisabled: true,
            delAuth: false,
            roleList: []
          });
        }
      }
    })
  }
}

const prjBtnStatus = (prjId, $store, callback) => {
  let admin = _.find($store.state.user.roleInfos, v => {
    return v.id == '-2';
  })
  
  let memberList = $store.state.currentParam.prjMemberListObj[prjId];
  let result = {
      addAuth: false,
      editAuth: false,
      isRoleDisabled: false,
      isExpireDisabled: false,
      delAuth: false,
      roleList: [
        {
          id: 2,
          name: '管理员'
        },
        {
          id: 3,
          name: '配置人员'
        }, {
          id: 4,
          name: '开发人员'
        }, {
          id: 5,
          name: '游客'
        }
      ]
  }
  if(memberList && memberList.length > 0)  {
    if(admin) {
      callback({
        addAuth: true,
        editAuth: true,
        isRoleDisabled: true,
        isExpireDisabled: true,
        delAuth: true,
        roleList: [
          {
            id: 2,
            name: '管理员'
          },
          {
            id: 3,
            name: '配置人员'
          }, {
            id: 4,
            name: '开发人员'
          }, {
            id: 5,
            name: '游客'
          }
        ]
      });
      return;
    }
    let userId = $store.state.user.userInfo.id;
    let member = memberList.filter(v => {return v.userId === userId});
    if(member && member.length > 0) {
      let date = new Date();
      let dateFormat = date.getFullYear() + '-' + ((date.getMonth() + 1) < 10 ? '0'+(date.getMonth() + 1) : (date.getMonth() + 1) ) + '-' + 
      (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
      if(Date.parse(member[0].expireDate) >= Date.parse(new Date(dateFormat))|| member[0].expireDate == null && callback) {
        result = roleAuth(member[0].roleId, result);
        callback(result);
      } 
    }else {
      callback({
        addAuth: false,
        editAuth: false,
        isRoleDisabled: true,
        isExpireDisabled: true,
        delAuth: false,
        roleList: []
      });
    }
  }else {
    api.sp.getAllPrjMembers({prjId: prjId}).then((res) => {
      memberList = res;
      if(memberList && memberList.length > 0) {
        $store.commit('setProjectMember', {id: prjId, list: res});
        if(admin) {
          callback({
            addAuth: true,
            editAuth: true,
            isRoleDisabled: true,
            isExpireDisabled: true,
            delAuth: true,
            roleList: [
              {
                id: 2,
                name: '管理员'
              },
              {
                id: 3,
                name: '配置人员'
              }, {
                id: 4,
                name: '开发人员'
              }, {
                id: 5,
                name: '游客'
              }
            ]
          });
          return;
        }
        let userId = $store.state.user.userInfo.id;
        let member = memberList.filter(v => {return v.userId === userId});
        if(member && member.length > 0) {
          let date = new Date();
          let dateFormat = date.getFullYear() + '-' + ((date.getMonth() + 1) < 10 ? '0'+(date.getMonth() + 1) : (date.getMonth() + 1) ) + '-' + 
          (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
          if(Date.parse(member[0].expireDate) >= Date.parse(new Date(dateFormat))|| member[0].expireDate == null && callback) {
            result = roleAuth(member[0].roleId, result);
            callback(result);
          } 
        }else {
          callback({
            addAuth: false,
            editAuth: false,
            isRoleDisabled: true,
            isExpireDisabled: true,
            delAuth: false,
            roleList: []
          });
        }
      }
    })
  }
}

const roleAuth = (roleId, obj) => {
    switch (parseInt(roleId)) {
      case 1:
        obj.addAuth= true;
        obj.editAuth = true;
        obj.isRoleDisabled = true;
        obj.isExpireDisabled = true;
        obj.delAuth = true;
        break;
      case 2:
        obj.addAuth= true;
        obj.editAuth = true;
        obj.isRoleDisabled = true;
        obj.isExpireDisabled = true;
        obj.delAuth = true;
        obj.roleList= [
          {
            id: 2,
            name: '管理员'
          },
          {
            id: 3,
            name: '配置人员'
          }, {
            id: 4,
            name: '开发人员'
          }, {
            id: 5,
            name: '游客'
          }
        ];
        break;
      case 3:
      case 4:
        obj.addAuth= true;
        obj.editAuth = true;
        obj.isRoleDisabled = false;
        obj.isExpireDisabled = false;
        obj.delAuth = true;
        break;
      case 5:
        obj.addAuth= false;
        obj.editAuth = false;
        obj.isRoleDisabled = false;
        obj.isExpireDisabled = false;
        obj.delAuth = false;
        break;
      default:
        break;
    }
    return obj;
  };

const getSpaceOrPrjDetails = (store) => {
  return new Promise( (resolve, reject) => {
    let spOrPrj = store.state.Global.spOrPrj;
    if(!spOrPrj) {
      api.sp.getCurrentModuleType().then((data) => {
        if('space' == data) {
          api.sp.getDetailById().then((res) => {
            if(res) 
              resolve(res);
          })
        } else if('project' == data) {
          api.sp.getPrjDetailById().then((res) => {
            if(res) 
              resolve(res);
          })
        }
      }) 
    }else {
      if('space' == spOrPrj) {
        api.sp.getDetailById().then((res) => {
          if(res) 
            resolve(res);
        })
      } else if('project' == spOrPrj) {
        api.sp.getPrjDetailById().then((res) => {
          if(res) 
            resolve(res);
        })
      }
    }
  }) 
};

const jumpRoute = (self, obj) => {
  return new Promise( (resolve, reject) => {
    
    if(obj.type == 1) {
      api.sp.setCurrentSpace({id: obj.id}).then(res => {
        self.$emit('changeNavi');
        self.$emit('setSpOrPrj', 'space');
        resolve();
      }) 
    }else {
      api.sp.setCurrentPrjKey({id: obj.id}).then(res => {
        self.$emit('changeNavi');
        self.$emit('setSpOrPrj', 'project');
        resolve();
      }) 
    }
  }) 
}

const jumpRouteSetup = (emit, obj) => {
  return new Promise( (resolve, reject) => {
    
    if(obj.type == 1) {
      api.sp.setCurrentSpace({id: obj.id}).then(res => {
        emit('changeNavi');
        emit('setSpOrPrj', 'space');
        resolve();
      }) 
    }else {
      api.sp.setCurrentPrjKey({id: obj.id}).then(res => {
        emit('changeNavi');
        emit('setSpOrPrj', 'project');
        resolve();
      }) 
    }
  }) 
}

export { btnStatus, prjBtnStatus, getSpaceOrPrjDetails, jumpRoute, jumpRouteSetup }